<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новый заказ"
        create
        url-update="/order/update/:id"
        url-back="/order"
    >
        <order-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import OrderForm from '@/components/forms/OrderForm.vue'
export default {
    name: 'OrderCreate',
    components: { FormView, OrderForm }
}
</script>